import { Component, inject, OnInit } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ModalComponent } from 'shared/components/modal/modal/modal.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ModalBaseComponent } from 'shared/components/modal/modal-base.component';
import { ModalData } from 'shared/components/modal/modal.service';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-bottom-sheet',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButton,
    NgForOf,
    NgClass,
    MatIcon,
    MatIconButton
  ],
  templateUrl: './bottom-sheet.component.html',
})
export class BottomSheetComponent extends ModalBaseComponent implements OnInit {
  override data = inject<ModalData>(MAT_BOTTOM_SHEET_DATA);
  //serviceRef = inject<MatBottomSheetRef <ModalComponent>>(MatBottomSheetRef);

  serviceRef = inject(MatBottomSheetRef<BottomSheetComponent>); 
 // serviceRef = inject(MatDialogRef<ModalComponent>);



  override _close() {
    this.serviceRef.dismiss();
  }
}
