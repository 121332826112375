  <div class="app-grid__toolbar">
    <mat-toolbar color="primary">
      <div class="app-grid__toolbar-left">
        <div class="search-input">
          <input [formControl]="searchFormControl" type="text" placeholder="search filter">
          @if (searchQueryResult) {
            <div class="search-result">{{searchQueryResult.found}} of {{searchQueryResult.total}}</div>
          }
        </div>
      </div>
      <div class="app-grid__toolbar-right">
        <button mat-icon-button>
          <mat-icon>density_medium</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon>density_small</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon>format_align_justify</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon>tune</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </div>
  <table
    class="app-grid__table"
    mat-table [dataSource]="(dataSource$ | async) ?? []">

    @for (column of columnsConfig; track column) {
      @if (column?.isActionColumn) {
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" [ngClass]="column.class">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              type="button"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="arrow_drop_down"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              @for (action of column.actions; track action) {
                @if (!action?.canShow) {
                  <button mat-menu-item (click)="action.handler(element, this, injector)" type="button">{{ action.label }}</button>
                } @else if (action?.canShow(element, this, injector)) {
                  <button mat-menu-item (click)="action.handler(element, this, injector)"  type="button">{{ action.label }}</button>
                }
              }
            </mat-menu>
          </td>
        </ng-container>
      } @else if (column?.template && hasTemplate(column.template)) {
        <ng-container [matColumnDef]="column.code">
          <th mat-header-cell *matHeaderCellDef [ngClass]="column.class">{{ column.label }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="column.class">
            <div class="app-grid__cell-content">
              <ng-container
                [ngTemplateOutlet]="getTemplate(column.template)"
                [ngTemplateOutletContext]="getTemplateContext(column, element)"
              />
            </div>
          </td>
        </ng-container>
      } @else {
        <ng-container [matColumnDef]="column.code">
          <th mat-header-cell *matHeaderCellDef [ngClass]="column.class">{{ column.label }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="column.class">
            {{ getCodeValue(element, column.code) | dynamicPipe : column.pipes ?? [] }}
          </td>
        </ng-container>
      }
    }
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="onSelect(row)"
        [class.selectable]="isSelectable()"
        [class.selected]="isSelected(row)"
    ></tr>
  </table>
  <mat-paginator [length]="paginationLength" [pageSize]="paginationPageSize" [pageSizeOptions]="paginationPageSizeOptions"></mat-paginator>
