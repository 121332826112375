import {
  ChangeDetectionStrategy,
  Component, effect, EventEmitter, inject,
  Injector, input,
  Input, isSignal,
  OnChanges,
  OnDestroy,
  OnInit, Output, Signal, TemplateRef,
  ViewChild, WritableSignal
} from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';

import { BehaviorSubject, isObservable, map, Observable, Subscription } from 'rxjs';
import { combineLatestWith } from 'rxjs/operators';
import { DynamicPipe } from '../../pipes/dynamic-pipe.pipe';
import { GridColumnType, GridViewType } from './grid-config';
import { toObservable } from '@angular/core/rxjs-interop';

interface GridChanges<T> {
  data?: {
    currentValue: Observable<T[]> | T[];
  }
  config?: {
    currentValue: GridViewType<any>
  };
}

@Component({
  standalone: true,
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatToolbarModule,
    CurrencyPipe,
    DatePipe,
    DynamicPipe,
    MatPaginator,
    ReactiveFormsModule
  ],
  host: {
    class: 'app-grid'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent<T> implements OnInit, OnDestroy, OnChanges {
  protected injector = inject(Injector);
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  @Input() set data(data: Observable<T[]> | T[] | Signal<T[]>) {
    if (isObservable(data)) {
      data.subscribe((data) => {
        this.data$.next(data);
      });
    } else if (isSignal(data)) {
      toObservable(data, {injector: this.injector}).subscribe((data) => {
        this.data$.next(data);
      });
    } else {
      this.data$.next(data);
    }
  };

  @Input() paginationPageSize = 10;
  @Input() paginationPageSizeOptions = [10, 20, 30];
  @Input() paginationLength = 10;
  @Input() config!: GridViewType<T>;
  @Input() isSelectedFunc!: (row: any) => boolean;
  @Input() templateRefs: {[key: string]: TemplateRef<any>} = {};

  @Output() select = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<void>();

  data$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  searchFormControl = new UntypedFormControl('');
  searchQuery = new BehaviorSubject<string>('');
  searchQueryResult: undefined | null | { total: number, found: number } = null;
  searchFilter = new BehaviorSubject<undefined | null | ((t: T) => boolean)>(null);
  subscription = new Subscription();
  dataSource$ = this.data$.pipe(
    combineLatestWith(this.searchQuery, this.searchFilter),
    map(([ items, searchQuery, searchFilter ]) => {
      if (searchQuery) {
        const total = items.length;
        items = items.filter((item: any) => {
          return this.columnsConfig.some((column) => {

            if (column.isActionColumn) {
              return false;
            }

            if (!item[column.code]) {
              return false;
            }


            return item[column.code].toString().toLowerCase().includes(searchQuery.toLowerCase());
          });
        });
        const found = items.length;
        this.searchQueryResult = { total, found };
      } else {
        this.searchQueryResult = null;
      }

      if (searchFilter) {
        items = items.filter(searchFilter);
      }

      const dataSource = new MatTableDataSource<T>(items);
      dataSource.paginator = this.paginator;
      return dataSource;
    })
  );

  get displayedColumns() {
   
    return this.columnsConfig.map((column) => column.code);
  }

  get columnsConfig(): GridColumnType<T>[] {
    return this.config.columns ?? [];
  }

  onRefresh() {
    this.refresh.emit();
  } 

  onSelect(row: any) {
    this.select.emit(row);
  }

  hasTemplate(template: string | undefined): boolean {
    if (!template) {
      return false;
    }
    return !!this.templateRefs[template];
  }

  getTemplate(template: string | undefined): TemplateRef<any> | null {
    if (!template) {
      return null;
    }
    return this.templateRefs[template] ?? null;
  }

  getTemplateContext(column: any, element: any): any {
    return {
      element: element,
      column: column
    };
  }

  isSelectable(): boolean {
    return !!this.isSelectedFunc;
  }

  isSelected(row: any): boolean {
    return this.isSelectedFunc ? this.isSelectedFunc(row) : false;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.searchFormControl.valueChanges.subscribe((searchQuery) => {
        this.searchQuery.next(searchQuery);
      })
    );

    this.searchFilter.next(this.config?.filter);

  }

  ngOnChanges(changes: GridChanges<T>): void {
    if (changes.config) {
      this.searchFilter.next(changes.config.currentValue?.filter);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getCodeValue(element: any, code: string): any {

    if (!code.includes('.')) {
      return element[code];
    }

    const parts = code.split('.');
    let value = element;
    for (const part of parts) {
      if (value) {
        value = value[part];
      } else {
        return undefined;
      }
    }
    return value;

  }

  protected readonly alert = alert;
}
