import { Pipe, PipeTransform, Injector, Type, ChangeDetectorRef } from '@angular/core';

export type DynamicPipeType = {
  pipe: Type<any>,
  args?: any
}

@Pipe({
  name: 'dynamicPipe',
  standalone: true,
})
export class DynamicPipe implements PipeTransform {
  constructor(
    protected injector: Injector,
    protected cd: ChangeDetectorRef,
  ) {}

  transform(value: any, pipes: DynamicPipeType[]): any {
    const providers = [];
    for (const pipe of pipes) {
      providers.push({ provide: pipe.pipe });
    }

    const injector = Injector.create({
      name: 'DynamicPipe',
      parent: this.injector,
      providers: providers
    })
    for (const pipe of pipes) {
      value = injector.get(pipe.pipe).transform(value, pipe.args)
    }

    return value;
  }

}
