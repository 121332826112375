@if (this.title) {
<h2 mat-dialog-title [innerHTML]="this.getTitle() | async"></h2>
}
<mat-dialog-content #htmlContainer>
  <ng-container #componentContainer />
</mat-dialog-content>
@if (this.buttons!.length > 0) {
  <mat-dialog-actions>
    <button mat-button mat-raised-button *ngFor="let button of buttons"
            type="button"
            [ngClass]="button.class"
            (click)="close(button)"
            [attr.color]="button.color"
    >{{ button.label }}
    </button>
  </mat-dialog-actions>
}
