import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  Renderer2,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { config2Button, MODAL_BUTTON, ModalButton, ModalComponentInterface } from "./modal.service";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ObjectUtils } from 'shared/utils/object-utils';

@Component({
  standalone: true,
  selector: 'app-confirm-modal',
  template: '',
  host: { class: 'app-confirm-modal' },
  imports: [
    CommonModule,
    MatButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class ModalBaseComponent implements ModalComponentInterface, OnInit, AfterViewInit, OnDestroy {
  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainerRef!: ViewContainerRef
  @ViewChild('htmlContainer', { read: ViewContainerRef }) htmlContainerRef!: ViewContainerRef

  protected subscription = new Subscription();
  protected destroy$?: Subject<void>;

  /**
   * The following properties comes from confirm modal services to
   * configure the modal.
   */


  /**
   * The title can be a string or translatable.
   * Will be showed in the header section of modal.
   * If not set then header will be hidden.
   */
  public title?: string;

  /**
   * The class that will be added to the modal.
   * @protected
   */
  protected class?: string;

  /**
   * The body can be a string, translatable or a component.
   */
  protected body?: string | any;

  /**
   * The buttons that will be displayed in the modal.
   * To hide button set it to null.
   * @protected
   */
  protected buttons?: ModalButton[] = [];

  // contains instance of component
  public componentInstance?: any;

  data!: any;

  constructor(
    protected renderer2: Renderer2,
    protected cd: ChangeDetectorRef
  ) {
  }

  abstract _close(): void;

  onNoClick(): void {
    this._close();
  }

  dismiss() {
    const button = this.getButton('dismiss');
    if (button?.action) {
      button.action(this, this.componentInstance);
    }
    this._close();
  }

  /**
   * If button is not provided then close it.
   * If provided then follow button action and button configuration
   *
   * @param action
   */
  close(action?: ModalButton | string) {
    if (!action) {
      this._close();
      return;
    }
    const button = this.getButton(action);
    if (!button) {
      this._close();
      return;
    }

    if (button?.action) {
      button.action(this, this.componentInstance);
    }

    if (button?.closeOnClick) {
      this._close();
    }
  }

  ngOnInit() {
    this.destroy$ = this.data.destroy$;
    this.class = this.data.class;
    this.title = this.data.title;
    this.body = this.data.body;
    this.buttons = this.data.buttons;
  }

  isTranslatable(element: any): boolean {
    return this.body instanceof Object && 'key' in this.body;
  }

  isString(text: any) {
    return typeof text === 'string' || text instanceof String;
  }

  isHtml(text: any): boolean {
    if (!this.isString(text)) {
      return false;
    }

    const pattern = /\s?<!doctype html>|(<html\b[^>]*>|<body\b[^>]*>|<x-[^>]+>)+/i;
    // We limit it to a reasonable length to improve performance.
    text = text.trim().slice(0, 1000);

    return pattern.test(text);
  }

  isComponent(element: any): boolean {
    return (element instanceof Function && 'name' in element);
  }

  getTitle() {
    return of(this.title);
  }

  getButton(button?: ModalButton | string): ModalButton | null {
    if (!this?.buttons || !button) {
      return null;
    }

    if (ObjectUtils.instanceOf(button, [ 'name', 'action' ])) {
      return button as ModalButton;
    }

    if (typeof button == 'string') {
      for (const button of this?.buttons) {
        if (button === button.name) {
          return button;
        }
      }
    }
    return null;
  }

  ngAfterViewInit(): void {
    if (this.isComponent(this.body)) {
      this.createComponent()
    } else if (this.isHtml(this.body)) {
      this.renderer2.setProperty(this.htmlContainerRef.element.nativeElement, 'innerHTML', this.body);
    } else if (this.isString(this.body)) {
      this.renderer2.setProperty(this.htmlContainerRef.element.nativeElement, 'innerHTML', `<p class="h4">${ this.body }</p>`);
    }
    this.cd.detectChanges();
  }

  createComponent(): Type<any> {
    this.componentContainerRef.clear();
    const componentRef: ComponentRef<any> = this.componentContainerRef
      .createComponent(this.body);

    componentRef.instance.data = this.data;
    const component = componentRef.instance;

    this.componentInstance = component;

    // Below logic is the equivalent to: if (component instanceof ConfirmModalInterface) {
    // Typescript does not support realtime type checking, so we have to check for the required methods
    if (!this.title && ObjectUtils.instanceOf(component, [ 'title' ])) {
      // do not override title provided in config
      this.title = component.title;
    }

    const requiredMethods = [ 'dismiss', 'confirm' ];
    if (ObjectUtils.instanceOf(component, requiredMethods)) {
      const componentButtons: { [key: string]: ModalButton } = {};
      // prioritise button config that was defined manually in the openModal call
      const preConfiguredButtons = this.buttons?.filter(a => !a.isDefault).forEach((button) => {
        if (button?.name) {
          componentButtons[button.name] = button;
        }
      });

      // do not override buttons configs provided in modal config
      const finalButtons: { [key: string]: ModalButton } = {};
      for (const method of requiredMethods) {
        if (method in componentButtons) {
          finalButtons[method] = componentButtons[method];
        } else if (component[method] !== null) {
          finalButtons[method] = config2Button(<MODAL_BUTTON>method, component[method]);
        }
      }
      this.buttons = Object.values(finalButtons);
    }

    return component;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.destroy$) {
      this.destroy$.next();
      this.destroy$.complete();
    }
  }
}
