import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ModalData } from "../modal.service";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { AsyncPipe, CommonModule, NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ModalBaseComponent } from 'shared/components/modal/modal-base.component';

@Component({
  standalone: true,
  selector: 'app-confirm-modal',
  templateUrl: './modal.component.html',
  host: {class: 'app-confirm-modal'},
  imports: [
    CommonModule,
    MatDialogTitle,
    MatDialogActions,
    MatDialogContent,
    MatButtonModule,
    NgClass,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent extends ModalBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  override data = inject<ModalData>(MAT_DIALOG_DATA);

  serviceRef = inject(MatDialogRef<ModalComponent>);

  override _close() {
    this.serviceRef.close();
  }
}
