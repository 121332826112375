<div class="app-bottom-sheet__close-button">
  <button (click)="close('dismiss')" mat-icon-button><mat-icon class="fa-light" fontSet="fa" fontIcon="fa-xmark" /></button>
</div>
<div class="app-bottom-sheet__header">
@if (this.title) {
  <h2 [innerHTML]="this.getTitle() | async"></h2>
}
</div>
<div class="app-bottom-sheet__body" #htmlContainer>
  <ng-container #componentContainer />
</div>
<div class="app-bottom-sheet__footer">
  @if (this.buttons!.length > 0) {
    <div class="app-bottom-sheet__footer-actions">
      <button mat-button mat-raised-button *ngFor="let button of buttons"
              type="button"
              [ngClass]="button.class"
              (click)="close(button)"
              [attr.color]="button.color"
      >{{ button.label }}
      </button>
    </div>
  }
</div>
