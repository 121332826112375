import { Injectable, OnDestroy, Type } from '@angular/core';
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ModalComponent } from "./modal/modal.component";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from 'shared/components/modal/bottom-sheet/bottom-sheet.component';



/**
 * ModalComponentInterface is the interface that should be implemented by the component
 * that will be used as body of the modal.
 * 
 * This does not seem to be implemented anywhere explictly.
 */
export interface ModalComponentInterface {
  /**
   * The data that will be passed to the modal component.
   */
  data: any;
  /**
   * The method that will be called when the modal is closed.
   */
  close():void;
}

export interface ModalInterface {
  title?: string;
  dismiss: ModalButtonType;
  confirm: ModalButtonType;
}

export enum MODAL_BUTTON {
  dismiss = 'dismiss',
  confirm = 'confirm'
}

export enum MODAL_TYPE {
  MODAL = 'MODAL',
  BOTTOM_SHEET = 'BOTTOM_SHEET'
}

export enum MODAL_SIZE {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xlg = 'xlg'
}

export const defaultSizeConfig: { [key in MODAL_SIZE]: { width?: string, height?: string } } = {
  [MODAL_SIZE.sm]: {
    width: '500px'
  },
  [MODAL_SIZE.md]: {
    width: '700px'
  },
  [MODAL_SIZE.lg]: {
    width: '900px'
  },
  [MODAL_SIZE.xlg]: {
    width: '1230px'
  }
}

export const defaultButtonConfigs: { [key in MODAL_BUTTON]: ModalButtonConfig } = {
  dismiss: {
    name: 'dismiss',
    class: 'mat-secondary',
    label: 'Cancel',
    color: 'secondary',
    closeOnClick: false
  },
  confirm: {
    name: 'confirm',
    class: 'mat-primary',
    label: 'Confirm',
    color: 'primary',
    closeOnClick: false
  }
}

export const defaultModalConfig: ModalConfig = {
  size: MODAL_SIZE.md,
  class: '',
  title: '',
  body: '',
  ...defaultButtonConfigs
};

export type ModalData<T = any> = {
  destroy$: Subject<void>;
  class?: string;
  title?: string;
  body?: string | T | Type<any>;
  buttons?: ModalButton<T>[];
  data?: any;
}

export type ModalConfig<T = any> = Omit<ModalData<T>, 'destroy$' | 'buttons'> & {
  type?: MODAL_TYPE
  size?: MODAL_SIZE;
  data?: any;
  dismiss?: ModalButtonType<T>;
  confirm?: ModalButtonType<T>;
}

export type ModalButtonType<T = any> = ModalButtonConfig<T> | ModalButtonCallback<T> | null
  ;

export type ModalButtonCallback<T = any> =
  ((modal: ModalComponentInterface, component: T) => void)
  | ((modal: ModalComponentInterface) => void)
  | (() => void)

export type ModalButtonConfig<T = any> = {
  name?: string;
  label?: string;
  class?: string;
  color?: string;
  action?: ModalButtonCallback<T>;
  isDefault?: boolean;
  closeOnClick?: boolean;
}

/**
 * Button could have additional properties or different types of some of them
 * For example label could be converted to translatable observable
 */
export type ModalButton<T = any> = ModalButtonConfig<T> & {}

export const config2Button = <T>(
  name: MODAL_BUTTON,
  config?: ModalButtonType<T>
): ModalButton<T> => {
  return buttonConfig2Button(defaultButtonConfigs[name], config);
}

export const buttonConfig2Button = <T>(
  defaultConfig: ModalButtonConfig<T>,
  config?: ModalButtonType<T>
): ModalButton<T> => {
  if (config === undefined || config instanceof Function) {
    return {
      name: defaultConfig?.name as string,
      label: defaultConfig?.label as string,
      class: defaultConfig.class,
      action: config === undefined ? undefined : config as ((component?: ModalComponent) => {}),
      isDefault: config === undefined,
      closeOnClick: defaultConfig?.closeOnClick
    };
  } else {
    return {
      name: config?.name ?? defaultConfig.name as string,
      label: config?.label ?? defaultConfig.label as string,
      class: config?.class ?? defaultConfig.class,
      action: config?.action ?? defaultConfig.action,
      closeOnClick: config?.closeOnClick ?? defaultConfig?.closeOnClick
    };
  }
}

/**
 * Usage:
 * this.modalService.openModal({
 *   body: { key: 'common.text.confirmReject' }
 *        or 'Are you sure you want to reject this request?',
 *        or '<h4>Are you sure you want to reject this request?<h4>',
 *   confirm: () => {
 *     alert('Confirmed');
 *   }
 * });
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService implements OnDestroy {
  protected subscription = new Subscription();
  protected destroy$ = new Subject<void>();

  constructor(
    protected dialog: MatDialog,
    protected bottomSheet: MatBottomSheet
  ) {
  }

  openBottomSheet<T>(config: ModalConfig<T>, bottomSheetConfig: MatBottomSheetConfig = {}) {
    bottomSheetConfig = {
      ...bottomSheetConfig
    }

    const size = config?.size ?? MODAL_SIZE.xlg;
    switch (size) {
      case MODAL_SIZE.xlg:
        //bottomSheetConfig.panelClass = ['mat-bottom-sheet-container-xlarge'];
        break;
    }


    const buttons: ModalButton<T>[] = this.getButtons(config);

    const bottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
      ...bottomSheetConfig,
      data: {
        destroy$: this.destroy$,
        class: config.class,
        title: config.title,
        body: config.body,
        buttons: buttons ?? [],
        data: config?.data
      }
    });

    if (bottomSheetRef) {
      this.subscription.add(
        bottomSheetRef.afterDismissed()
          .pipe(takeUntil(this.destroy$))
          .subscribe()
      );
    }

    console.log('bottomSheetRef', bottomSheetRef);

  }

  openModal<T>(config: ModalConfig<T>, dialogConfig: MatDialogConfig = {}) {
    dialogConfig = {
      ...dialogConfig,
      ...defaultSizeConfig[config?.size ?? MODAL_SIZE.md],
    }

    const buttons: ModalButton<T>[] = this.getButtons(config);

    const dialogRef = this.dialog.open(ModalComponent, {
      ...dialogConfig,
      data: {
        destroy$: this.destroy$,
        class: config.class,
        title: config.title,
        body: config.body,
        buttons: buttons ?? [],
        data: config?.data
      },
    });

    if (dialogRef) {
      this.subscription.add(
        dialogRef.afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe()
      );
    }
  }

  getButtons<T>(
    config: Pick<ModalConfig<T>, "dismiss" | "confirm">,
    skip: MODAL_BUTTON[] = []
  ): ModalButton<T>[] {
    const buttons: ModalButton<T>[] = [];

    // build buttons
    let buttonName: MODAL_BUTTON;
    for (buttonName in defaultButtonConfigs) {
      if (config[buttonName] === null || skip.includes(buttonName)) {
        continue;
      }
      const button = config2Button(buttonName, config[buttonName] ?? undefined);
      buttons.push(button);
    }

    return buttons;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }
}
